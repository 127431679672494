/* Add this to your main CSS file */
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@400;700&display=swap');

/* index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0;
  font-family: 'Roboto Slab', serif;
}

.container1 {
  text-align: center;
  color: white;
}

.logo {
  font-size: 1.5rem;
  font-weight: bold;
}

.tagline {
  font-size: 1rem;
  margin-top: 10px;
}

.tm {
  font-size: 0.8rem;
  border: 2px solid white;
  border-radius: 50%;
  padding: 2px 6px;
  position: relative;
  top: -15px;
  left: 10px;
  font-weight: bold;
}